export const folderConstants = {
    FETCH_FOLDER_CONTENT_REQUEST: "FETCH_FOLDER_CONTENT_REQUEST",
    FETCH_FOLDER_CONTENT_SUCCESS: "FETCH_FOLDER_CONTENT_SUCCESS",
    FETCH_FOLDER_CONTENT_FAILURE: "FETCH_FOLDER_CONTENT_FAILURE",
    FETCH_ROOT_FOLDER_REQUEST: "FETCH_ROOT_FOLDER_REQUEST",
    FETCH_ROOT_FOLDER_SUCCESS: "FETCH_ROOT_FOLDER_SUCCESS",
    FETCH_ROOT_FOLDER_FAILURE: "FETCH_ROOT_FOLDER_FAILURE",
    CREATE_FOLDER_REQUEST: "CREATE_FOLDER_REQUEST",
    CREATE_FOLDER_SUCCESS: "CREATE_FOLDER_SUCCESS",
    CREATE_FOLDER_FAILURE: "CREATE_FOLDER_FAILURE",
    CREATE_FILE_REQUEST: "CREATE_FILE_REQUEST",
    CREATE_FILE_SUCCESS: "CREATE_FILE_SUCCESS",
    CREATE_FILE_FAILURE: "CREATE_FILE_FAILURE",
    UPDATE_FOLDER_REQUEST: "UPDATE_FOLDER_REQUEST",
    UPDATE_FOLDER_SUCCESS: "UPDATE_FOLDER_SUCCESS",
    UPDATE_FOLDER_FAILURE: "UPDATE_FOLDER_FAILURE",
    DELETE_FOLDER_REQUEST: "DELETE_FOLDER_REQUEST",
    DELETE_FOLDER_SUCCESS: "DELETE_FOLDER_SUCCESS",
    DELETE_FOLDER_FAILURE: "DELETE_FOLDER_FAILURE",
    COPY_FOLDER_REQUEST: "COPY_FOLDER_REQUEST",
    COPY_FOLDER_SUCCESS: "COPY_FOLDER_SUCCESS",
    COPY_FOLDER_FAILURE: "COPY_FOLDER_FAILURE",
    DELETE_FILE_REQUEST: "DELETE_FILE_REQUEST",
    DELETE_FILE_SUCCESS: "DELETE_FILE_SUCCESS",
    DELETE_FILE_FAILURE: "DELETE_FILE_FAILURE",
    UPDATE_FILE_REQUEST: "UPDATE_FILE_REQUEST",
    UPDATE_FILE_SUCCESS: "UPDATE_FILE_SUCCESS",
    UPDATE_FILE_FAILURE: "UPDATE_FILE_FAILURE",
    DUPLICATE_FILE_REQUEST: "DUPLICATE_FILE_REQUEST",
    DUPLICATE_FILE_SUCCESS: "DUPLICATE_FILE_SUCCESS",
    DUPLICATE_FILE_FAILURE: "DUPLICATE_FILE_FAILURE",
    DOWNLOAD_FOLDER_REQUEST: "DOWNLOAD_FOLDER_REQUEST",
    DOWNLOAD_FOLDER_SUCCESS: "DOWNLOAD_FOLDER_SUCCESS",
    DOWNLOAD_FOLDER_FAILURE: "DOWNLOAD_FOLDER_FAILURE",
    SEARCH_FOLDERS_REQUEST: "SEARCH_FOLDERS_REQUEST",
    SEARCH_FOLDERS_SUCCESS: "SEARCH_FOLDERS_SUCCESS",
    SEARCH_FOLDERS_FAILURE: "SEARCH_FOLDERS_FAILURE",
    FETCH_FOLDERS_BY_SHIPMENT_REQUEST: "FETCH_FOLDERS_BY_SHIPMENT_REQUEST",
    FETCH_FOLDERS_BY_SHIPMENT_SUCCESS: "FETCH_FOLDERS_BY_SHIPMENT_SUCCESS",
    FETCH_FOLDERS_BY_SHIPMENT_FAILURE: "FETCH_FOLDERS_BY_SHIPMENT_FAILURE",
    RESET_FETCH_ROOT_FOLDER_SUCCESS: "RESET_FETCH_ROOT_FOLDER_SUCCESS",
    RESET_CREATE_FOLDER_SUCCESS: "RESET_CREATE_FOLDER_SUCCESS",
    RESET_UPDATE_FOLDER_SUCCESS: "RESET_UPDATE_FOLDER_SUCCESS",
    RESET_DELETE_FOLDER_SUCCESS: "RESET_DELETE_FOLDER_SUCCESS",
    RESET_COPY_FOLDER_SUCCESS: "RESET_COPY_FOLDER_SUCCESS",
    RESET_CREATE_FILE_SUCCESS: "RESET_CREATE_FILE_SUCCESS",
    RESET_FETCH_FOLDER_CONTENT_SUCCESS: "RESET_FETCH_FOLDER_CONTENT_SUCCESS",
    RESET_DELETE_FILE_SUCCESS: "RESET_DELETE_FILE_SUCCESS",
    RESET_UPDATE_FILE_SUCCESS: "RESET_UPDATE_FILE_SUCCESS",
    RESET_DUPLICATE_FILE_SUCCESS: "RESET_DUPLICATE_FILE_SUCCESS",
    RESET_DOWNLOAD_FOLDER_SUCCESS: "RESET_DOWNLOAD_FOLDER_SUCCESS",
    RESET_SEARCH_FOLDERS_SUCCESS: "RESET_SEARCH_FOLDERS_SUCCESS",
};
