export const rateConstants = {
    FETCH_HOT_DEALS_REQUEST: "FETCH_HOT_DEALS_REQUEST",
    FETCH_HOT_DEALS_SUCCESS: "FETCH_HOT_DEALS_SUCCESS",
    FETCH_HOT_DEALS_FAILURE: "FETCH_HOT_DEALS_FAILURE",
    FETCH_PORTS_REQUEST: "FETCH_PORTS_REQUEST",
    FETCH_PORTS_SUCCESS: "FETCH_PORTS_SUCCESS",
    FETCH_PORTS_FAILURE: "FETCH_PORTS_FAILURE",
    FETCH_RATES_REQUEST: "FETCH_RATES_REQUEST",
    FETCH_RATES_SUCCESS: "FETCH_RATES_SUCCESS",
    FETCH_RATES_FAILURE: "FETCH_RATES_FAILURE",
    FETCH_SINGLE_RATE_REQUEST: "FETCH_SINGLE_RATE_REQUEST",
    FETCH_SINGLE_RATE_SUCCESS: "FETCH_SINGLE_RATE_SUCCESS",
    FETCH_SINGLE_RATE_FAILURE: "FETCH_SINGLE_RATE_FAILURE",
    FF_CREATE_RATE_REQUEST: "FF_CREATE_RATE_REQUEST",
    FF_CREATE_RATE_SUCCESS: "FF_CREATE_RATE_SUCCESS",
    FF_CREATE_RATE_FAILURE: "FF_CREATE_RATE_FAILURE",
    FETCH_PRODUCTS_REQUEST: "FETCH_PRODUCTS_REQUEST",
    FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
    FETCH_PRODUCTS_FAILURE: "FETCH_PRODUCTS_FAILURE",
    FETCH_FF_RATES_REQUEST: "FETCH_FF_RATES_REQUEST",
    FETCH_FF_RATES_SUCCESS: "FETCH_FF_RATES_SUCCESS",
    FETCH_FF_RATES_FAILURE: "FETCH_FF_RATES_FAILURE",
    FF_ADD_MULTIPLE_RATES_REQUEST: "FF_ADD_MULTIPLE_RATES_REQUEST",
    FF_ADD_MULTIPLE_RATES_SUCCESS: "FF_ADD_MULTIPLE_RATES_SUCCESS",
    FF_ADD_MULTIPLE_RATES_FAILURE: "FF_ADD_MULTIPLE_RATES_FAILURE",
    FF_TOGGLE_RATE_ACTIVENESS_REQUEST: "FF_TOGGLE_RATE_ACTIVENESS_REQUEST",
    FF_TOGGLE_RATE_ACTIVENESS_SUCCESS: "FF_TOGGLE_RATE_ACTIVENESS_SUCCESS",
    FF_TOGGLE_RATE_ACTIVENESS_FAILURE: "FF_TOGGLE_RATE_ACTIVENESS_FAILURE",
    FF_UPDATE_RATE_REQUEST: "FF_UPDATE_RATE_REQUEST",
    FF_UPDATE_RATE_SUCCESS: "FF_UPDATE_RATE_SUCCESS",
    FF_UPDATE_RATE_FAILURE: "FF_UPDATE_RATE_FAILURE",
    FETCH_PRODUCTS_FOR_RATES_TEMPLATE_REQUEST: "FETCH_PRODUCTS_FOR_RATES_TEMPLATE_REQUEST",
    FETCH_PRODUCTS_FOR_RATES_TEMPLATE_SUCCESS: "FETCH_PRODUCTS_FOR_RATES_TEMPLATE_SUCCESS",
    FETCH_PRODUCTS_FOR_RATES_TEMPLATE_FAILURE: "FETCH_PRODUCTS_FOR_RATES_TEMPLATE_FAILURE",
    FETCH_PORTS_FOR_RATES_TEMPLATE_REQUEST: "FETCH_PORTS_FOR_RATES_TEMPLATE_REQUEST",
    FETCH_PORTS_FOR_RATES_TEMPLATE_SUCCESS: "FETCH_PORTS_FOR_RATES_TEMPLATE_SUCCESS",
    FETCH_PORTS_FOR_RATES_TEMPLATE_FAILURE: "FETCH_PORTS_FOR_RATES_TEMPLATE_FAILURE",
    RESET_FF_CREATE_RATE_SUCCESS: "RESET_FF_CREATE_RATE_FAILURE",
    RESET_FF_ADD_MULTIPLE_RATES_SUCCESS: "RESET_FF_ADD_MULTIPLE_RATES_SUCCESS",
    RESET_FF_TOGGLE_RATE_ACTIVENESS_SUCCESS: "RESET_FF_TOGGLE_RATE_ACTIVENESS_SUCCESS",
    RESET_FF_UPDATE_RATE_SUCCESS: "RESET_FF_UPDATE_RATE_SUCCESS",
    RESET_FETCH_FF_RATES_SUCCESS: "RESET_FETCH_FF_RATES_SUCCESS",
    RESET_FETCH_PRODUCTS_FOR_RATES_TEMPLATE_SUCCESS:
        "RESET_FETCH_PRODUCTS_FOR_RATES_TEMPLATE_SUCCESS",
    RESET_FETCH_PORTS_FOR_RATES_TEMPLATE_FAILURE: "RESET_FETCH_PORTS_FOR_RATES_TEMPLATE_FAILURE",
};
