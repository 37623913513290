export const paymentConstants = {
    FETCHING_PAYMENT_DATA: "FETCHING_PAYMENT_DATA",
    FETCHING_PAYMENT_DATA_SUCCESS: "FETCHING_PAYMENT_DATA_SUCCESS",
    FETCHING_PAYMENT_DATA_FAILURE: "FETCHING_PAYMENT_DATA_FAILURE",
    CONVERT_CURRENCY_REQUEST: "CONVERT_CURRENCY_REQUEST",
    CONVERT_CURRENCY_SUCCESS: "CONVERT_CURRENCY_SUCCESS",
    CONVERT_CURRENCY_FAILURE: "CONVERT_CURRENCY_FAILURE",
    ADD_BANK_ACCOUNT_REQUEST: "ADD_BANK_ACCOUNT_REQUEST",
    ADD_BANK_ACCOUNT_SUCCESS: "ADD_BANK_ACCOUNT_SUCCESS",
    ADD_BANK_ACCOUNT_FAILURE: "ADD_BANK_ACCOUNT_FAILURE",
    RESOLVE_BANK_DETAILS_REQUEST: "RESOLVE_BANK_DETAILS_REQUEST",
    RESOLVE_BANK_DETAILS_SUCCESS: "RESOLVE_BANK_DETAILS_SUCCESS",
    RESOLVE_BANK_DETAILS_FAILURE: "RESOLVE_BANK_DETAILS_FAILURE",
    FETCH_BANKS_REQUEST: "FETCH_BANKS_REQUEST",
    FETCH_BANKS_SUCCESS: "FETCH_BANKS_SUCCESS",
    FETCH_BANKS_FAILURE: "FETCH_BANKS_FAILURE",
    DELETE_BANK_ACCOUNT_REQUEST: "DELETE_BANK_ACCOUNT_REQUEST",
    DELETE_BANK_ACCOUNT_SUCCESS: "DELETE_BANK_ACCOUNT_SUCCESS",
    DELETE_BANK_ACCOUNT_FAILURE: "DELETE_BANK_ACCOUNT_FAILURE",
    FETCH_FF_PAYMENT_LINKS_REQUEST: "FETCH_FF_PAYMENT_LINKS_REQUEST",
    FETCH_FF_PAYMENT_LINKS_SUCCESS: "FETCH_FF_PAYMENT_LINKS_SUCCESS",
    FETCH_FF_PAYMENT_LINKS_FAILURE: "FETCH_FF_PAYMENT_LINKS_FAILURE",
    CREATE_PAYMENT_LINK_REQUEST: "CREATE_PAYMENT_LINK_REQUEST",
    CREATE_PAYMENT_LINK_SUCCESS: "CREATE_PAYMENT_LINK_SUCCESS",
    CREATE_PAYMENT_LINK_FAILURE: "CREATE_PAYMENT_LINK_FAILURE",
    RESET_CREATE_PAYMENT_LINK_SUCCESS: "RESET_CREATE_PAYMENT_LINK_SUCCESS",
    FETCH_PAYMENT_DATA: "FETCH_PAYMENT_DATA",
};
